import React, { useState } from 'react'
import { PaginationProps } from '../../types'
import { navigate } from 'gatsby'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { trackEvent } from '../../utils/ArtboxoneTracking'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'right',
    },
  }),
)

const getUrlbyTemplateAndNumber = (baseUrl: string, paginationUrlTemplate: string, pageNumber: number) => {
  if (pageNumber === 1) {
    return baseUrl;
  }

  const withoutHash = baseUrl.split('#')[0]
  const hash = baseUrl.includes('#') ? '#' + baseUrl.split('#')[1] : '';
  const url = withoutHash + paginationUrlTemplate.replace('%d', '' + pageNumber) + hash
  return url
}

const Pagination = (props: any) => {
  const classes = useStyles()

  const pagination: PaginationProps = props.pagination

  let currentPageNumber = pagination.currentPage

  const pagesArray = []

  let begin = currentPageNumber - 1

  if (begin < 1) {
    begin = 1
  }

  let end = begin + 2

  const pageCount = pagination.pageCount

  let hasPrev = false
  let hasNext = false

  if (pageCount !== undefined) {
    if (pagination.pageCount !== 0) {
      if (end > pageCount) {
        end = pageCount
      }
    }

    if (end == 3) {
      begin = 1
    }

    hasPrev = currentPageNumber > 1
    hasNext = currentPageNumber < pageCount || pageCount === 0
  }

  for (let i = begin; i <= end; i++) {
    pagesArray.push(i)
  }

  const handleClick = (event: any) => {
    const pageNumber = parseInt(event.currentTarget.dataset.page)

    trackEvent({
      category: 'Pagination',
      action: 'NavigateTo',
      label: event.currentTarget.dataset.pageanalytics,
    })

    currentPageNumber = pageNumber

    navigate(getUrlbyTemplateAndNumber(pagination.baseUrl, pagination.urlTemplate, pageNumber))
    event.preventDefault()
  }

  if (isNaN(props.pagination.pageCount)) {
    return <></>
  } else if (pageCount === undefined) {
    return <></>
  } else if (pageCount === 0) {
    return <></>
  } else {
    return (
      <div className={classes.root}>
        <ButtonGroup aria-label="Navigation" variant="contained">
          <Button
            color="secondary"
            data-page={currentPageNumber - 1}
            data-pageanalytics="prev"
            disabled={hasPrev === false}
            onClick={handleClick}
            role="link"
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </Button>
          {pagesArray.map((pageNumber: number, i) => (
            <Button
              color={pageNumber === currentPageNumber ? 'primary' : 'secondary'}
              data-page={pageNumber}
              data-pageanalytics={pageNumber}
              key={
                pagination.template + '_' + pageNumber + '_' + currentPageNumber
              }
              onClick={handleClick}
              role="link"
            >
              {pageNumber}
            </Button>
          ))}
          <Button
            color="secondary"
            data-page={currentPageNumber + 1}
            data-pageanalytics="next"
            disabled={hasNext === false}
            onClick={handleClick}
            role="link"
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </Button>
        </ButtonGroup>
      </div>
    )
  }
}

export default Pagination
